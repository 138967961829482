<template>
  <div class="informative-page _container">
    <h1>{{ pageData.title }}</h1>
    <div v-html="pageData.content"></div>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';

export default {
  data() {
    return {
      pageData: {
        title: '',
        content: ''
      },
      error: null
    };
  },
  mounted() {
    this.fetchPageData();
  },
  watch: {
    // Відстежуємо зміни параметрів маршруту
    '$route.params.pageType': {
      immediate: true,  // дозволяє спостерігачу викликатись при першому завантаженні компонента
      handler() {
        this.fetchPageData();
      }
    }
  },
  methods: {
    async fetchPageData() {
      try {
        const pageType = this.$route.params.pageType;  // отримуємо параметр маршруту
        const response = await axiosInstance.get(`/api/v1/pages/page/${pageType}/`);
        this.pageData = response.data;
        this.error = null;  // скидаємо попередню помилку, якщо вона була
      } catch (error) {
        this.error = 'Не вдалося завантажити дані сторінки.';
        console.error('Error fetching page data:', error);
      }
    }
  }
};
</script>

<style scoped>
.informative-page {
  padding: 20px;
  margin: 0 auto;
}

.informative-page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.informative-page div {
  font-size: 1.1rem;
  line-height: 1.6;
}

.error {
  color: red;
  margin-top: 20px;
}
</style>
