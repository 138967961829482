<template>
    <div class="cart-summary">
      <h3>Ваш кошик</h3>
      <div v-for="item in cartItems" :key="item.id" class="cart-item">
        <span class="title">{{ item.product_name }}</span>
        <div class="cart__item-price">
            <div class="price_p">
                <p :class="{ 'crossed-price': item.discount_price > 0 }">
                    {{ item.price }}₴
                </p>
                <p class='disc_price' v-if="item.discount_price > 0">
                    {{ item.discount_price }}₴
                </p>
                <p v-if="!isEditable">x {{ item.qty }}</p>

            </div> 
        </div>
        <div class="js-qty__wrapper" v-if="isEditable"> <!-- Використовуємо v-if -->
            <button type="button" class="js-qty__adjust js-qty__adjust--minus" @click="delProduct(item.id, '-1')">
              <span class="icon__fallback-text" aria-hidden="true">−</span>
            </button>
            <input type="number" v-model="item.qty" class="js-qty__num" @input="updateQuantityProduct(item.id, item.qty)" min="1" :max="item.stock" />
            <button type="button" class="js-qty__adjust js-qty__adjust--plus" @click="addProduct(item.id, '1')">
              <span class="icon__fallback-text" aria-hidden="true">+</span>
            </button>
        </div>
        <span class='product_sum'>
        {{ (item.discount_price > 0 ? item.discount_price : item.price) * item.qty }}₴
        </span>

      </div>
      <div class="total">
        <h4>Загальна сума: {{ totalPrice }} грн</h4>
      </div>
    </div>
  </template>
  
  <script>
import '@/assets/css/checkout.css';
import '@/assets/css/cart.css';
import { mapActions } from 'vuex';

  export default {
    name: 'CartSummary',
    props: {
      isEditable: {
        type: Boolean,
        default: true, // За замовчуванням редагування ввімкнене
      }
    },
    computed: {
      cartItems() {
        // Отримуємо товари з кошика
        return this.$store.getters['cart/cartItems'];
      },
      totalPrice() {
        // Загальна сума товарів у кошику
        return this.$store.getters['cart/totalPrice'];
      }
    },
    methods: {
        ...mapActions('cart', ['updateCartItemQuantity', 'deleteProductFromCart', 'addRemoveCart', 'delRemoveCart']),  
        addProduct(variantId, qty) {
      try {
        this.addRemoveCart(variantId, qty); 
      } catch (error) {
          console.error("Error adding product to cart:", error);
      }},
    delProduct(variantId, qty) {
      try {
        this.delRemoveCart(variantId, qty); 
      } catch (error) {
          console.error("Error adding product to cart:", error);
      }},
    deleteProduct(variantId) {
      try {
        this.deleteProductFromCart(variantId);
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    },
    updateQuantityProduct(variantId, qty) {
      if (qty >= 1) {
        this.updateCartItemQuantity({ variantId, qty });
      }
    },
    triggerViewCartEvent() {
      const products = this.cartItems.map(item => ({
        id: item.id,
        name: item.product_name,
        price: item.price,
        discount_price: item.discount_price,
        quantity: item.qty
      }));

      // Пушимо подію viewCart до даталайера
      window.dataLayer.push({
        'event': 'viewCart',
        'ecommerce': {
          'cart': {
            'products': products,
            'total': this.totalPrice
          }
        }
      });
    }
  },
    created() {
      this.$store.dispatch('cart/fetchCartProducts').then(() => {
        this.triggerViewCartEvent();  // Викликаємо подію після завантаження товарів
      });  // Завантажуємо товари в кошик при завантаженні компонента
    }
  };
  </script>

  
  <style scoped>
  .cart-summary {
    padding: 20px;
    border: 1px solid #ccc;
  }
  .cart-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }
  .total {
    margin-top: 20px;
  }
  .title {
    width: 200px;
  }
  .product_sum { 
    width: 100px;
  }
  @media (max-width:1210px) {
    .cart-summary {
        padding: 20px 10px;
    }
    .title {
        width: 180px;
        font-size: 0.7rem;
    }   
    .product_sum {
        width: 65px;
    }
  }
  @media (max-width:466px) {
    .title { 
      width: 110px;
      font-size: 0.7rem;
    }
    .product_sum,
    .cart__item-price {
      font-size: 0.7rem;
      width: 40px;
    }
  }
  @media (max-width:466px) { 
    .js-qty__adjust,
    .js-qty__num {
      width: 25px;
    }
  }
  </style>
