<template>
  <div class="login-container">
    <form @submit.prevent="handleLogin" class="login-form">
      <h2>Авторизація користувача</h2>
      <div class="form-group">
        <label for="email">Email</label>
        <input 
          type="email" 
          id="email" 
          v-model="email" 
          required 
          placeholder="Введіть ваш email" 
        />
      </div>

      <div class="form-group password-group">
        <label for="password">Пароль</label>
        <div class="password-input-wrapper">
          <input 
            :type="showPassword ? 'text' : 'password'" 
            id="password" 
            v-model="password" 
            required 
            placeholder="Введіть ваш пароль" 
          />
          <span class="toggle-password" @click="togglePassword">
            <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
              <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8s4-8 11-8c3.43 0 6.58 1.28 9 3.54"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          </span>
        </div>
      </div>

      <button type="submit" class="login-button">Вхід</button>

      <div class="form-links">
        <router-link to="/register">Реєстрація</router-link>
        <router-link to="/reset-password">Забули пароль?</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "LoginPage",
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    async handleLogin() {
      try {
        await this.login({ email: this.email, password: this.password });
        this.$router.push('/account'); // Переадресація на сторінку аккаунта після успішного входу
      } catch (error) {
        console.error('Помилка входу:', error);
        alert('Невірний email або пароль');
      }
    }
  }
};
</script>

<style src="@/assets/css/Login.css"></style>
