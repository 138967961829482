<template>
  <Carousel :autoplay="5000" :wrap-around="true">
    <Slide v-for="(banner, index) in selectedBanners" :key="index">
      <img :src="banner" alt="Banner" class="banner-image" />
    </Slide>
  </Carousel>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'BannerCarousel',
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
  },
  setup(props) {
    const isMobile = ref(window.innerWidth < 768); // Статус мобільного вікна

    // Computed property to select the appropriate banner based on screen size
    const selectedBanners = computed(() => {
      return props.banners.map(banner => 
        isMobile.value ? banner.big_banner_mobile : banner.big_banner
      );
    });

    // Function to handle the resize event
    const handleResize = () => {
      isMobile.value = window.innerWidth < 768; // Оновлення статусу мобільного вікна
    };

    // Add a resize event listener to handle window resizing
    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    // Clean up the resize event listener on component unmount
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      selectedBanners,
    };
  },
});
</script>

<style>
.banner-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>
