import instance from '@/api/axiosInstance';  // Імпортуємо axios інстанс


const state = {
    personalInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
    },
    deliveryInfo: {},
    deliveryType: '', // Тип доставки
    paymentMethod: '', // Спосіб оплати
    discount: 0, // Знижка від промокоду
    appliedPromoCode: null, // Застосований промокод
    cartItems: [], // Товари в кошику (отримані з іншого модуля або API)
    totalSum: 0,
    delivery_cost: 0,
    orderId: localStorage.getItem('order_id') || null, 
 
};
const mutations = {
    SET_PERSONAL_INFO(state, payload) {
    state.personalInfo = payload;
    },
    SET_DELIVERY_INFO(state, payload) {
    state.deliveryInfo = payload;
    },
    SET_DELIVERY_TYPE(state, payload) {
    state.deliveryType = payload;
    state.paymentMethod = '';
    },
    SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload;
    },
    SET_DISCOUNT(state, payload) {
    state.discount = payload;
    },
    SET_PROMO_CODE(state, promoCode) {
        console.log(promoCode);
    state.appliedPromoCode = promoCode;
    },
    SET_CART_ITEMS(state, cartItems) {
    state.cartItems = cartItems;
    },
    SET_TOTAL_SUM(state, sum) {
        state.totalSum = sum;
    },
    SET_ORDER_ID(state, orderId) {
        localStorage.removeItem('order_id');
        localStorage.setItem('order_id', orderId);
    },
    CLEAR_ALL_DATA(state) {
        state.personalInfo = {
            firstName: '',
            lastName: '',
            phone: '',
            email: ''
        };
        
        state.deliveryInfo = {}; // Очищення інформації про доставку
        state.deliveryType = ''; // Тип доставки
        state.paymentMethod = ''; // Спосіб оплати
        state.discount = 0; // Знижка від промокоду
        state.appliedPromoCode = null; // Застосований промокод
        state.cartItems = []; // Очищення кошика
        state.totalSum = 0; // Загальна сума
        state.delivery_cost = 0; // Вартість доставки
    }
};

const actions = {
    savePersonalInfo({ commit }, personalInfo) {
    commit('SET_PERSONAL_INFO', personalInfo);
    },
    saveDeliveryInfo({ commit }, deliveryInfo) {
    commit('SET_DELIVERY_INFO', deliveryInfo);
    },
    saveDeliveryType({ commit }, deliveryType) {
    commit('SET_DELIVERY_TYPE', deliveryType);
    },
    savePaymentMethod({ commit }, paymentMethod) {
    commit('SET_PAYMENT_METHOD', paymentMethod);
    },
    saveTotalSum({commit}, sum) {
        commit('SET_TOTAL_SUM', sum);
    },
    clearAllData({ commit }) {
        commit('CLEAR_ALL_DATA');
      },
    applyPromoCode({ commit }, promoCode) {
        // Перевірка промокоду
        instance.post('/api/v1/orders/promocode/validate/', { code: promoCode })
        .then(response => {
            const discount = response.data.discount; // Наприклад, отримуємо знижку в відсотках
            commit('SET_DISCOUNT', discount); // Зберігаємо знижку у % в стані
            commit('SET_PROMO_CODE', promoCode); // Зберігаємо сам промокод
        })
        .catch(() => {
            commit('SET_DISCOUNT', 0); // Якщо промокод недійсний, скидаємо знижку
            commit('SET_PROMO_CODE', null); // Очищаємо промокод
        });
    },      
    // eslint-disable-next-line
    async createOrder({ state, getters, commit }) {
        try {
        // Обчислюємо тотал суми за допомогою геттера
        const total_sum = getters.totalSum;
    
        // Зберігаємо тотал суму у стейт через мутацію
        commit('SET_TOTAL_SUM', total_sum);
    
        // Отримуємо всі дані для замовлення
        const orderData = getters.orderData;
    
        // Відправляємо запит для створення замовлення
        const response = await instance.post('/api/v1/orders/create/', orderData);
    
        if (response.status === 201) {
            const orderId = response.data.id; // Отримуємо order_id з відповіді
            commit('SET_ORDER_ID', orderId);
            return response.data; // Успішно створене замовлення
        }
        } catch (error) {
        console.error('Помилка створення замовлення:', error);
        throw error; // Повертаємо помилку, щоб обробити її в компоненті
        }
    }
};
const getters = {
    orderData: (state, getters, rootState) => {
    return {
        personal_info: state.personalInfo,
        delivery_info: state.deliveryInfo,
        delivery_type: state.deliveryType,
        payment_method: state.paymentMethod,
        cart_items: rootState.cart.cartProducts, // Додаємо товари з модуля кошика
        discount: state.discount,
        delivery_cost: getters.deliveryCost,
        total_price: getters.totalSum,
        promo_code: state.appliedPromoCode
    };
    },
    totalPrice: (state, getters, rootState) => {
        console.log('cartProducts:', rootState.cart.cartProducts);  // Перевіряємо, чи є товари у кошику
        return rootState.cart.cartProducts.reduce((total, item) => {
          console.log('Item Price:', item.price, 'Quantity:', item.qty);
          return total + parseFloat(item.price) * item.qty;
        }, 0);
      }
,          
    deliveryCost: (state, getters) => {
    if (getters.totalPrice < 3500 && state.deliveryType === 'courier') {
        return 150;
    }
    return 0;
    },
    totalSum: (state, getters) => {
        const totalPrice = parseFloat(getters.totalPrice) || 0;  // Перетворення на число
        const deliveryCost = parseFloat(getters.deliveryCost) || 0;  // Перетворення на число
        const discountAmount = (totalPrice * state.discount) / 100 || 0;  // Перевірка на нуль
      
        const finalTotal = totalPrice + deliveryCost - discountAmount;
        return finalTotal.toFixed(2);  // Повертаємо фінальну суму як число
    }};          
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};