<template>
  <div>
    <h4>Виберіть спосіб оплати:</h4>
    <div class="payment-options">
      <button 
        class="payment-button" 
        :class="{ active: paymentMethod === 'full' }" 
        @click="selectPaymentMethod('full')" 
        :disabled="!isDeliveryInfoComplete"
      >
        Повна оплата карткою
      </button>
      <button 
        class="payment-button" 
        :class="{ active: paymentMethod === 'cash' }" 
        @click="selectPaymentMethod('cash')" 
        :disabled="!isDeliveryInfoComplete"
      >
        Готівкою
      </button>
    </div>

    <div class="courier-delivery">
      <h4>Доставка кур'єром</h4>

      <label>Місто:</label>
      <input v-model="courierCity" type="text" disabled placeholder="Київ" />

      <label>Вулиця:</label>
      <input 
        v-model="courierStreet" 
        type="text" 
        placeholder="Введіть вулицю" 
        @input="updateCourierInfo" 
        required
      />

      <label>Дім:</label>
      <input 
        v-model="courierHouse" 
        type="text" 
        placeholder="Введіть номер будинку" 
        @input="updateCourierInfo" 
        required
      />

      <label>Квартира/Офіс:</label>
      <input 
        v-model="courierApartment" 
        type="text" 
        placeholder="Введіть номер квартири або офісу" 
        @input="updateCourierInfo" 
        required
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      courierCity: 'Київ', // Фіксоване значення для міста
      courierStreet: '',
      courierHouse: '',
      courierApartment: '',
      paymentMethod: null // Початково не вибрано метод оплати
    };
  },
  computed: {
    // Перевіряємо, чи всі поля заповнені
    isDeliveryInfoComplete() {
      return this.courierStreet && this.courierHouse && this.courierApartment;
    }
  },
  methods: {
    ...mapActions('checkout', ['saveDeliveryInfo', 'savePaymentMethod']),
    
    // Оновлюємо інформацію про доставку кур'єром
    updateCourierInfo() {
      this.saveDeliveryInfo({
        courierCity: this.courierCity,
        courierStreet: this.courierStreet,
        courierHouse: this.courierHouse,
        courierApartment: this.courierApartment
      });
    },
    
    // Вибір способу оплати
    selectPaymentMethod(method) {
      if (this.isDeliveryInfoComplete) {
        this.paymentMethod = method;
        this.savePaymentMethod(method);
      }
    }
  }
};

</script>

<style scoped>
.courier-delivery {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.courier-delivery label {
  font-weight: bold;
}

.courier-delivery input {
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 1rem;
}

.payment-options {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.payment-button {
  text-transform: uppercase;
  background: #fff;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  width: 100%;
  letter-spacing: 1px;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px;
  font-family: 'MaisonNeue';
  margin-bottom: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.payment-button:hover {
  background-color: #fff;
  color: #000;
}

.payment-button.active {
  background-color: #000;
  color: #fff;
  border-color: #000;
}

.payment-button:disabled {
  background-color: #f0f0f0;
  color: #aaa;
  border-color: #ccc;
  cursor: not-allowed;
}

</style>
