<template>
  <div class="checkout-container ">
    <!-- Ліва колонка з формою доставки -->
    <div class="checkout-left">
      <ShippingForm />
    </div>
    
    <!-- Права колонка з підсумком замовлення та товарів -->
    <div class="checkout-right">
      <CartSummary />
      <OrderSummary />
    </div>
  </div>
</template>

<script>
import ShippingForm from './components/ShippingForm.vue';
import CartSummary from './components/CartSummary.vue';
import OrderSummary from './components/OrderSummary.vue';

export default {
  name: 'CheckoutPage',
  components: {
    ShippingForm,
    CartSummary,
    OrderSummary
  }
};
</script>

<style scoped>
.checkout-container {
  display: flex;
  justify-content: space-evenly;
    margin: 100px 30px;
    align-items: center;

}

.checkout-left, .checkout-right {
  width: 48%; /* Обидві частини займають по половині сторінки */
}
@media (max-width: 980px) {
  .checkout-container {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  .checkout-left {
    width: 99%;
  }
  .checkout-right {
    width: 99%;
  }
}
@media (max-width: 980px){ 
  .checkout-right{ 
    width: 90%;
}}


</style>
