<template>
    <div>
      <div class="skin-type-info">
        <h1>{{ productTypeData.name }}</h1> <!-- Назва типу шкіри -->
      </div>
      <ProductCards :products="productTypeData.products_with_variants"   @pc_s_close_cart="PTCloseCart" @pc_s_close_wish="PTCloseWish"/> <!-- Продукти з варіантами -->
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router'; // Для доступу до параметрів маршруту
  import instance from '@/api/axiosInstance'; // Axios інстанс для API запитів
  import ProductCards from '@/components/ProductCards.vue'; // Імпорт компоненту для відображення продуктів
  
  export default {
    name: 'ProductTypePage',
    components: {
      ProductCards,
    },
    methods: {
      PTCloseCart() {
      this.$emit('home_close_cart');
    },
      PTCloseWish() {
      this.$emit('home_close_wish');
    },
  
    },
    setup() {
      const productTypeData = ref({
        name: '',
        products_with_variants: [], // Продукти з варіантами
      });
  
      const route = useRoute(); // Отримуємо поточний маршрут
  
      const fetchProductTypeData = async (slug) => {
        try {
          const response = await instance.get(`/api/v1/shop/product-types/${slug}/`); // Запит до API для отримання даних
          productTypeData.value = response.data; // Збереження отриманих даних
          console.log(response.data)
        } catch (error) {
          console.error('Error fetching skin type data:', error); // Логування помилок
        }
      };
  
      onMounted(() => {
        const slug = route.params.slug || route.path.split('/').pop(); // Отримуємо slug з маршруту
        fetchProductTypeData(slug); // Викликаємо функцію для отримання даних
      });
  
      return {
        productTypeData,
      };
    },
  };
  </script>
  
  <style scoped>
  .skin-type-info {
    text-align: center;
    margin: 20px 0;
  }
  
  .skin-type-info h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 10px;
  }
  
  .skin-type-info p {
    font-size: 1.2rem;
    color: #666;
  }
  </style>
  