<template>
  <div class="cart_slide_components">
    <transition name="fade">
      <div v-if="isCartVisible" class="cart-bg" @click="closeCart"></div>
    </transition>
    <transition name="cart">
      <div class="cart-slide" v-if="isCartVisible">
        <div class="title_cart">
          <div class="close-cart">
            <a>Вітаємо Нішер!</a>
            <button type="button" class="close" @click="closeCart">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <h5>Твій кошик</h5>
        </div>
        <div class="cart_form">
          <div class="cart_scrollable">
            <div class="cart_data">
              <!-- Перевірка, чи є товари в кошику -->
              <div v-if="cartItems.length > 0">
                <div class="cart_items">
                  <div class="cart_item" v-for="(item, index) in cartItems" :key="item.id || index">
                    <div class="cart_img">
                      <a :href="item.url" class="image-wrap">
                        <img :src="getImageUrl(item.image)" :alt="item.title">
                      </a>
                    </div>
                    <div class="cart_detail">
                      <div class="cart_item_title">
                        <div class="title__wrapper">
                          <a :href="item.url" class="cart__item-name grid-product__title">
                            {{ item.product_name }}
                          </a>
                          <button type="button" class="js-qty__adjust js-qty__adjust--remove" @click="deleteProduct(item.id)">
                            <span class="icon__delete-button" aria-hidden="true">
                              <img :src="delete_button" alt="Delete" />
                            </span>
                          </button>
                        </div>
                        <a class="grid-product__subtitle">{{ item.value }} {{ item.unit }}</a>
                      </div>
                      <div class="cart_item_sub">
                        <div class="js_qty_main">
                          <div class="js-qty__wrapper">
                            <button type="button" class="js-qty__adjust js-qty__adjust--minus" @click="delProduct(item.id, '-1')">
                              <span class="icon__fallback-text" aria-hidden="true">−</span>
                            </button>
                            <input type="number" v-model="item.qty" class="js-qty__num" @input="updateQuantityProduct(item.id, item.qty)" min="1">

                            <button type="button" class="js-qty__adjust js-qty__adjust--plus" @click="addProduct(item.id, '1')">
                              <span class="icon__fallback-text" aria-hidden="true">+</span>
                            </button>
                          </div>
                          <div class="js_qty_wrapper">
                            <div class="cart__item-price">
                              <div class="price_p">
                                <p :class="{ 'crossed-price': item.discount_price > 0 }">
                                  {{ item.price }}₴
                                </p>
                                <p class='disc_price' v-if="item.discount_price > 0">
                                  {{ item.discount_price }}₴
                                </p>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> <!-- cart_item -->
                </div> <!-- cart_items -->
              </div>
              <!-- Якщо кошик порожній -->
              <div v-else class="empty-cart-message">
                <p>Твій кошик порожній</p>
              </div>
            </div> <!-- cart_data -->
          </div> <!-- cart_scrollable -->
          <!-- Відображаємо підсумок лише якщо є товари -->
          <div class="cart_footer" v-if="cartItems.length > 0">
            <div class="cart_subtotal">
              <div class="subtotal">Сума замовлення:</div>
              <div class="subtotal">{{ totalPrice }} грн.</div>  <!-- Загальна вартість кошика -->
            </div>
            <div class="payment_button">
              <a href="/checkout">Замовити</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import delete_button from '@/assets/img/ico/delete-button.svg';
import instance from '@/api/axiosInstance';  // Імпорт axios інстансу
import '@/assets/css/cart.css';

export default {
  name: "CartComponent",
  props: {
    isCartVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseURL: instance.defaults.baseURL,  // Отримуємо базовий URL
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItems', 'totalPrice']),  // Отримуємо товари з кошика та загальну вартість
    delete_button() {
      return delete_button;
    },
  },
  methods: {
    closeCart() {
      this.$emit('close_cart');
    },
    ...mapActions('cart', ['updateCartItemQuantity', 'deleteProductFromCart', 'addRemoveCart', 'delRemoveCart']),  // Використовуємо методи для роботи з кошиком
    getImageUrl(imagePath) {
      return `${this.baseURL}${imagePath}`;  // Використовуємо зворотні лапки
    },
    addProduct(variantId, qty) {
      try {
        this.addRemoveCart(variantId, qty); 
      } catch (error) {

          console.error("Error adding product to cart:", error);
    }},
    delProduct(variantId, qty) {
      try {
        this.delRemoveCart(variantId, qty); 
      } catch (error) {

          console.error("Error adding product to cart:", error);
    }},
    deleteProduct(variantId) {
      try {
        console.log('Deleting product with variantId:', variantId);
        this.deleteProductFromCart(variantId);  // Передаємо лише саме значення variantId
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    },

    updateQuantityProduct(variantId, qty) {
    if (qty >= 1) {
      this.updateCartItemQuantity({ variantId, qty });  // Відправляємо нову кількість у Vuex
    }
  },
  },
  created() {
    this.$store.dispatch('cart/fetchCartProducts');  // Завантажуємо товари в кошик при завантаженні компонента
  }
};
</script>


<style scoped>
/* CART BACKGROUND */
.cart-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; 
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
/* !! */
/* CART SLIDE */
/* Стилі для панельки */
.cart-slide {
  background: #fff;
  width: 20rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0px;
  z-index: 1001; 
  transform: translateX(0);
}

/* В'їзд панелі з анімацією */
.cart-enter-active, .cart-leave-active {
  transition: transform 0.3s ease-in; /* Швидше закриття */
}

.cart-enter, .cart-leave-to {
  transform: translateX(100%);
}
/* !!! */
.close-cart {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
    float: right;
    width: 100%;
    text-align: right;
}
.close {
    border: none;
    background: none;
}
.title_cart {
    padding: 10px 15px 10px 15px;
    display: flex;
    flex-direction: column;
    float: unset;
    clear: both;
    border-bottom: 1px solid #e8e8e8;
}
.title__wrapper {
  display: flex;
  justify-content: space-between;
}
.title_cart a {
    font-weight: 900;
    font-size: 1.3rem;
    text-transform: uppercase;
}
.title_cart h5 {
    font-size: 1rem;
    font-family: "MaisonNeue", sans-serif;
    font-weight: 300;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
.cart_form {
    padding: 20px 0 150px;
    /* overflow: hidden; */
    height: 85vh;
}
.cart_data {
    box-sizing: border-box;
}
.cart_item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-bottom-color: #ffffff;
}
.cart-image {
    flex: 0 0 35%;
    margin-right: 10px;
}
.cart-image a {
    position: relative;
    display: block;
    width: 100%;
    height: 100px;
}
.cart_detail {
    flex: 1 1 65%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.cart_item_title {
    flex: 1 1 50%;
    padding: 0 10px 0 0;
}
.cart__item-name {
    flex: 1 1 50%;
    display: block;
    font-size: calc(var(--typeBaseSize) + 1px);
    margin-bottom: 8px;
    line-height: 16px;
}
.grid-product__subtitle {
    font-weight: 600;
    margin: 6px 0;
    hyphens: auto;
    font-size: 12px;
    word-break: unset;
    word-wrap: unset;
    overflow-wrap: unset;
    -webkit-hyphens: unset;
    -moz-hyphens: unset;
    -ms-hyphens: unset;
    hyphens: unset;
    line-height: 14px;
    font-weight: 400;
}
.grid-product__capacity {
    color: #000 !important;
    font-size: 10px;
    display: block;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 8px;
}
.cart__item-sub {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.js_qty_main {
    display: flex;
    flex-wrap: wrap;
}
.cart__item-price {
    margin-left: 10px;
    text-align: right !important;
}
.cart__price {
    font-weight: 500;
    font-size: 15px;
}
.js-qty__wrapper {
    display: inline-block;
    position: relative;
    min-width: 60px;
    overflow: visible;
    background-color: #ffffff;
    color: #000000;
}
.js-qty__adjust {
    background: #fff;
    height: 35px;
    width: 35px;
    text-align: center;
    border: 1px solid #000;
    line-height: 24px;
    padding: 5px 6px;
    display: inline-block;
    float: left;
    cursor: pointer;
    backface-visibility: hidden;
    transition: background-color 0.1s ease-out;
}
.js-qty__num {
    background: #fff;
    height: 31px;
    width: 35px;
    text-align: center;
    border: 1px solid #000;
    line-height: 24px;
    padding: 1px 6px;
    display: inline-block;
    float: left;
    margin: 0 -1px 10px;
    font-size: .9rem;
    -moz-appearance: textfield;
}
.js-qty__num::-webkit-outer-spin-button,
.js-qty__num::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Для Chrome, Safari, Edge */
  margin:0;
}
.js-qty__adjust--remove {
  margin-left: 8px;
  border: none;
}
.js-qty__adjust--remove img{
  max-width: 100%;
}
.cart_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #ffffff;

    box-shadow: rgb(0 0 0 / 10%) 2px -5px 10px 0;
}
.cart_subtotal {
    margin: 5px 0px;
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 10px 0;
}

.payment_button a {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr;
    cursor: pointer !important;
    margin-bottom: 5px;
}
.payment_button a {
    display: flex !important;
    align-items: center;
    text-align: center !important;
    justify-content: center;
    font-family: MaisonNeue;
    line-height: inherit;
    text-transform: uppercase;
    opacity: 1;
    color: #000;
    font-weight: 900;
    background: #fff;
    border: 1px solid #000000;
    height: 42px;
    border-radius: 0 !important;
    margin-top: 3px !important;
    letter-spacing: 1px;
    letter-spacing: 1px;
    margin: 0 auto;
    min-width: 165px;
    width: 95%;
    min-height: 54px;
}
.payment_button a:hover {
  background: #000;
  color: #fff;
}
.image-wrap img {
  width: 100px;
  height: auto;
}

.cart_form {
  padding: 20px 0 20px; /* Встанови менше відступу знизу */
  height: 85vh; /* Висота всього кошика */
}

.cart_scrollable {
  height: calc(85vh - 200px); /* Висота для контейнера з товарами */
  overflow-y: auto; /* Дозволити вертикальну прокрутку */
}
.crossed-price, 
.top-price{
  margin: 0;
}
.empty-cart-message {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  margin: 20px 0;
}
</style>