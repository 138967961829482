<template>
  <div class="account-container">
    <div class="profile">
      <!-- Ліва колонка з меню -->
      <div class="menu-column">
        <nav>
          <ul class='navigation'>
            <li @click="currentSection = 'profile'" :class="{ active: currentSection === 'profile' }">
              <img :src="profileIcon" alt="profile icon" class="menu-icon" />
              <span class="menu-text">Особиста інформація</span>
            </li>
            <li @click="currentSection = 'orders'" :class="{ active: currentSection === 'orders' }">
              <img :src="ordersIcon" alt="orders icon" class="menu-icon" />
              <span class="menu-text">Мої замовлення</span>
            </li>
            <li @click="handleLogout" class="logout-button">
              <img :src="logoutIcon" alt="logout icon" class="menu-icon" />
              <span class="menu-text">Вийти</span>
            </li>
          </ul>
        </nav>
      </div>

      <!-- Права колонка з контентом -->
      <section class="content">
        <!-- Відображаємо відповідну секцію на основі вибору в меню -->
        <div v-if="currentSection === 'profile'" class="profile-section">
          <div class="header">
            <h2>Особисті дані</h2>
            <button @click="saveUserData" class="edit-button">Зберегти</button>
          </div>

          <div class="profile-info-grid">
            <div class="profile-info-item">
              <span class="label">Ім'я:</span>
              <input v-model="user.first_name" class="value" />
            </div>
            <div class="profile-info-item">
              <span class="label">Прізвище:</span>
              <input v-model="user.last_name" class="value" />
            </div>
          </div>

          <div class="header">
            <h2>Контакти</h2>
          </div>

          <div class="profile-info-grid">
            <div class="profile-info-item">
              <span class="label">Номер телефону:</span>
              <div class="phone-wrapper">
                <span class="prefix">+38</span>
                <input v-model="phoneNumber" @input="formatPhoneNumber" class="value" maxlength="13" placeholder="___-___-__-__" />
              </div>
              <span v-if="errors.phone" class="error-message">Невірний номер телефону</span>
            </div>
            <div class="profile-info-item">
              <span class="label">Email:</span>
              <input v-model="user.email" class="value" />
            </div>
          </div>
        </div>

        <!-- Замовлення -->
        <div v-else-if="currentSection === 'orders'" class="orders-section">
          <h2>Мої замовлення</h2>
          <div v-if="orders.length > 0">
            <div class="order" v-for="(order, index) in orders" :key="order.id">
              <div 
                class="order-summary"
                @click="toggleOrderDetails(index)" 
              >
              <span class="order-number">Замовлення #{{ order.id }}</span>

                <!-- Статус оплати з кольоровим кружечком -->
                <span class="order-status">
                  Оплата:
                  <span :class="{'status-circle green': order.payed, 'status-circle red': !order.payed}"></span>
                </span>

                <span class="order-total">Сума: {{ order.total_price }} грн</span>

                <!-- Форматована дата -->
                <span class="order-time">Час замовлення: {{ formatDate(order.created) }}</span>
              </div>

              <div v-if="expandedOrder === index" class="order-products">
                <h3>Продукти у замовленні:</h3>
                <ul>
                  <li v-for="product in order.order_products" :key="product.variant">
                    {{ product.name }} - {{ product.quantity }} шт. за {{ product.price }} грн
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-else>
            <p>У вас ще немає замовлень.</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import profileIcon from '@/assets/img/ico/account/my_account.svg'; // Імпорт іконки профілю
import ordersIcon from '@/assets/img/ico/account/my_backet.svg'; // Імпорт іконки замовлень
import logoutIcon from '@/assets/img/ico/account/logout.svg'; // Імпорт іконки для виходу
import instance from '@/api/axiosInstance'; // Використовуємо axios для API запитів
import Cookies from 'js-cookie'; // Використовуємо куки для аутентифікації

export default {
  name: 'AccountPage',
  data() {
    return {
      currentSection: 'profile', // Відображаємо розділ "Особиста інформація" за замовчуванням
      user: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        city: ''
      },
      phoneNumber: '',  // Створюємо нову змінну для форматованого номеру
      errors: { phone: false },
      orders: [],
      expandedOrder: null, // Додаємо змінну для відкритого замовлення
      profileIcon, // Імпортована іконка профілю
      ordersIcon, // Імпортована іконка замовлень
      logoutIcon // Імпортована іконка для виходу
    };
  },
  mounted() {
    this.fetchUserData(); // Викликаємо метод для отримання даних користувача та замовлень
  },
  methods: {
    async fetchUserData() {
      try {
        const token = Cookies.get('authToken');
        if (token) {
          const response = await instance.get('/api/v1/actions/orders/', {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.user = response.data.user; // Отримуємо інформацію про користувача
          this.orders = response.data.orders; // Отримуємо замовлення
          console.log(response.data);
          // Форматуємо номер телефону користувача
          if (this.user.phone_number) {
            this.phoneNumber = this.formatUserPhoneNumber(this.user.phone_number.slice(3));  // Видаляємо +38
          }
        }
      } catch (error) {
        await this.refreshToken();
        console.error('Помилка отримання даних користувача:', error);
      }
    },
    toggleOrderDetails(index) {
      this.expandedOrder = this.expandedOrder === index ? null : index; 
    },
    formatPhoneNumber() {
      let rawNumber = this.phoneNumber.replace(/\D/g, '');
      if (rawNumber.length > 10) rawNumber = rawNumber.slice(0, 10);

      if (rawNumber.length > 6) {
        rawNumber = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 8)}-${rawNumber.slice(8)}`;
      } else if (rawNumber.length > 3) {
        rawNumber = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
      }
      this.phoneNumber = rawNumber;
    },
    formatUserPhoneNumber(phone) {
      let rawNumber = phone.replace(/\D/g, '');
      if (rawNumber.length > 10) rawNumber = rawNumber.slice(-10);
      if (rawNumber.length > 6) {
        return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 8)}-${rawNumber.slice(8)}`;
      } else if (rawNumber.length > 3) {
        return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
      }
      return rawNumber;
    },
    validatePhoneNumber() {
      const rawNumber = this.phoneNumber.replace(/\D/g, '');
      if (rawNumber.length !== 10) {
        this.errors.phone = true;
        return false;
      }
      this.errors.phone = false;
      this.user.phone_number = '+38' + rawNumber; // Зберігаємо у форматі +38XXXXXXXXXX
      return true;
    },
    async saveUserData() {
      if (this.validatePhoneNumber()) {
        try {
          const token = Cookies.get('authToken');
          if (token) {
            await instance.put('/api/v1/actions/update/', this.user, {
              headers: { Authorization: `Bearer ${token}` },
            });
            console.log('Дані успішно збережено');
          }
        } catch (error) {
          console.error('Помилка збереження даних користувача:', error);
        }
      } else {
        console.log('Невірний номер телефону');
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('uk-UA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    async refreshToken() {
      try {
        const refreshToken = Cookies.get('refresh_token');
        if (refreshToken) {
          const response = await instance.post('/api/v1/auth/refresh/', {
            refresh: refreshToken,
          });
          Cookies.set('authToken', response.data.access); // Оновлюємо токен доступу
          this.fetchUserData(); // Повторно запитуємо дані після оновлення токену
        } else {
          await this.$router.push('/login');
        }
      } catch (error) {
        console.error('Помилка оновлення токену:', error);
        await this.handleLogout(); // Виконуємо логаут, якщо не вдалося оновити токен
      }
    },
    async handleLogout() {
      Cookies.remove('authToken'); // Видаляємо токен
      Cookies.remove('refresh_token'); // Видаляємо refresh токен
      await this.$router.push('/login'); // Перенаправляємо на сторінку логіну
      window.location.reload(); // Оновлюємо сторінку після перенаправлення
    }
  }
};
</script>

<style scoped>
.account-container {
  position: relative;
  width: calc(100% - 128px);
  margin: 0 auto;
}
.menu-column img {
  width: 32px;
  height: 32px;
  position: relative;
  display: block;
}
.profile {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-gap: 48px;
  min-height: 600px;
  margin-top: 60px;
}
.navigation {
  position: relative;
  display: grid;
  grid-gap: 16px;
  background-color: var(--clr-1-25);
  border-radius: 16px;
  min-height: 240px;
}
.navigation li {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 32px;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
}
.navigation li:hover{
  cursor: pointer;
  background: whitesmoke;
}
.content { 
  position: relative;
  display: grid;
  grid-gap: 24px;
  border-radius: 16px;
  border: 2px solid var(--grey-light);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.edit-button {
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.profile-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.profile-info-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.profile-info-item input {
  width: calc(95% - 4px);
  padding: 0.75rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 1rem;
}
.label {
  font-weight: 600;
  color: #666;
}

.value {
  font-weight: 400;
  color: #333;
}
.content {
  width: 70%;
}
.header button {
  font-size: 18px;
  font-weight: 500;
}
.orders-section {
  margin-top: 20px;
}

.order {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.order-summary:hover {
  background-color: #f0f0f0;
}

.order-number, .order-status, .order-delivery, .order-total, .order-time {
  flex-basis: 20%;
  text-align: center;
}

.order-products {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.order-products h3 {
  margin-bottom: 10px;
}

.order-products ul {
  list-style: none;
  padding: 0;
}

.order-products ul li {
  margin-bottom: 5px;
}
.phone-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 960px) {
  .content {
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
  .header {
    display: block;
  }
  .profile {
    grid-template-columns: 56px 1fr;
    grid-gap: 16px;
    margin-left: -20px;
  }
  .menu-text {
    display: none;
  }
  .profile .navigation li {
    display: grid;
    grid-template-columns: 0;
    grid-gap: 32px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-radius: 4px;
  }
  .account-container { 
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .profile{ 
    margin-left: -40px;
  }
  .profile-info-grid {
    grid-template-columns: unset;
  }
}

.status-circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
}
.green {
  background-color: green;
}
.red {
  background-color: red;
}
</style>