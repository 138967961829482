<template>
  <div class="delivery-options">
    <h3>Вибір доставки</h3>
    <div class="delivery-types">
      <div class="delivery-type" :class="{ selected: deliveryType === 'nova_poshta' }" @click="selectDelivery('nova_poshta')">
        <img :src="novaPoshtaImage" alt="Нова Пошта" />
        <p>Нова Пошта</p>
      </div>
      <div class="delivery-type" :class="{ selected: deliveryType === 'courier' }" @click="selectDelivery('courier')">
        <img :src="courierImage" alt="Кур'єр" />
        <p>Кур'єр</p>
      </div>
      <div class="delivery-type" :class="{ selected: deliveryType === 'pickup' }" @click="selectDelivery('pickup')">
        <img :src="pickupImage" alt="Самовивіз" />
        <p>Самовивіз</p>
      </div>
    </div>

    <!-- Відображаємо відповідний компонент залежно від вибору -->
    <NovaPoshtaDelivery v-if="deliveryType === 'nova_poshta'" />
    <CourierDelivery v-if="deliveryType === 'courier'" />
    <PickupDelivery v-if="deliveryType === 'pickup'" />
  </div>
</template>

<script>
import novaPoshtaImage from '@/assets/img/delivery/newpost.png';
import courierImage from '@/assets/img/delivery/courier.png';
import pickupImage from '@/assets/img/delivery/pick-up.png'; // Іконка для самовивозу

import NovaPoshtaDelivery from './delivery/NovaPoshtaDelivery.vue';
import CourierDelivery from './delivery/CourierDelivery.vue';
import PickupDelivery from './delivery/PickupDelivery.vue';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'DeliveryOptions',
  components: {
    NovaPoshtaDelivery,
    CourierDelivery,
    PickupDelivery
  },
  computed: {
    ...mapState('checkout', ['deliveryType']),
    novaPoshtaImage() {
      return novaPoshtaImage;
    },
    courierImage() {
      return courierImage;
    },
    pickupImage() {
      return pickupImage;
    }
  },
  methods: {
    ...mapActions('checkout', ['saveDeliveryType']),
    selectDelivery(type) {
      this.saveDeliveryType(type);
    }
  }
};
</script>

<style scoped>
.delivery-types {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.delivery-type {
  cursor: pointer;
  text-align: center;
  width: 30%;
}

.delivery-type img {
  width: 100%;
  height: 100px;
}
@media (max-width: 600px){
  .delivery-type img {
    height: 80px;
} 
}
@media (max-width: 485px){
  .delivery-type img {
    height: 50px;
} 
}

.delivery-type.selected {
  border: 2px solid #000;
}

  .delivery-options {
    width: calc(100% - 120px);
  }
  .delivery-types{
    justify-content: space-between;
  }

</style>
