import instance from '@/api/axiosInstance';  // Імпортуємо axios інстанс

export default {
  namespaced: true,
  state: {
    cart: JSON.parse(localStorage.getItem('cart')) || [],  // Ініціалізуємо з localStorage або порожнім масивом
    cartProducts: [],  // Зберігаємо продукти для відображення в кошику
  },
  mutations: {
    ADD_TO_CART(state, { variantId, qty }) {
      const existingItem = state.cart.find(item => item.variantId === variantId);

      if (existingItem) {
        existingItem.qty += qty;  // Збільшуємо або зменшуємо кількість товару

        // Якщо кількість товару менше або дорівнює 0, видаляємо його з кошика
        if (existingItem.qty <= 0) {
          state.cart = state.cart.filter(item => item.variantId !== variantId);
        
        }
      } else if (qty > 0) {
        // Додаємо новий товар у кошик, якщо його ще не було і `qty` більше за 0
        state.cart.push({ variantId, qty });
      
      }

      localStorage.setItem('cart', JSON.stringify(state.cart));  // Оновлюємо localStorage

    },

    UPDATE_CART_ITEM_QUANTITY(state, { variantId, qty }) {
      const existingItem = state.cart.find(item => item.variantId === variantId);
      if (existingItem) {
        existingItem.qty = qty;  // Оновлюємо кількість товару
        if (existingItem.qty <= 0) {
          state.cart = state.cart.filter(item => item.variantId !== variantId);  // Видаляємо, якщо кількість 0
        }
        localStorage.setItem('cart', JSON.stringify(state.cart));  // Оновлюємо localStorage після зміни кількості
      }
    },

    REMOVE_FROM_CART(state, variantId) {
      
        state.cart = state.cart.filter(item => {
          return item.variantId !== variantId;  // Порівнюємо variantId як просте значення
        });
      
        localStorage.setItem('cart', JSON.stringify(state.cart));  // Оновлюємо localStorage після видалення
      },
      
      
    SET_CART_PRODUCTS(state, products) {

      // Оновлюємо кількість товару на основі збереженого варіанту в кошику
      state.cartProducts = products.map(product => {
        const cartItem = state.cart.find(item => item.variantId === product.id);  // Знаходимо товар у кошику за ID
        return {
          ...product,
          price: parseFloat(product.price),  // Перетворюємо price у число
          discount_price: parseFloat(product.discount_price),  // Перетворюємо discount_price у число
          qty: cartItem ? cartItem.qty : 1,  // Ініціалізуємо поле qty кількістю з кошика або 1 за замовчуванням
        };
      });

    },
    CLEAR_CART(state) {
        localStorage.setItem('cart', JSON.stringify([])); 
        state.cart = [];
        state.cartProducts = [];
      }
  },
  actions: {
    addRemoveCart({ commit, dispatch, state }, variantId, qty = 1) {
        commit('ADD_TO_CART', { variantId, qty });
        dispatch('fetchCartProducts');  // Оновлюємо список товарів у кошику після додавання
    
        // Знаходимо продукт в cartProducts за його variantId
        const productInCart = state.cartProducts.find(product => product.id === variantId);
    
        if (productInCart) {
          const { id, code, price, discount_price, product_name } = productInCart;
            
          // Пушимо подію addToCart до даталайера з необхідними полями
          window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
              'currencyCode': 'UAH',
              'add': {
                'product': [{
                  'id': id,
                  'code': code,  // Якщо у продукту є поле code
                  'product_name': product_name,
                  'price': price,  // Можна використовувати discount_price, якщо є
                  'discount_price': discount_price,
                  'quantity': qty
                }]
              }
            }
          });
        }
      },
    delRemoveCart({ commit, dispatch }, variantId, qty = -1) {
        commit('ADD_TO_CART', { variantId, qty });
        dispatch('fetchCartProducts');  // Оновлюємо список товарів у кошику після додавання
      },
    updateCartItemQuantity({ commit, dispatch }, { variantId, qty }) {
      commit('UPDATE_CART_ITEM_QUANTITY', { variantId, qty });
      dispatch('fetchCartProducts'); 
    },
    deleteProductFromCart({ commit, dispatch }, variantId) {
        commit('REMOVE_FROM_CART', variantId);  // Передаємо просте значення variantId, а не об'єкт
        dispatch('fetchCartProducts'); 
      },
    async fetchCartProducts({ state, commit }) {
      if (state.cart.length === 0) {
        commit('SET_CART_PRODUCTS', []);  // Якщо немає товарів, очищуємо список
        return;
      }

      const variantIds = state.cart.map(item => item.variantId);
      try {
        const response = await instance.post('/api/v1/shop/wishlist/', { variant_ids: variantIds });
        commit('SET_CART_PRODUCTS', response.data);
      } catch (error) {
        console.error('Error fetching cart products:', error);
      }
    },
    clearCart({ commit }) {
        commit('CLEAR_CART');
    },
  },
  getters: {
    cartItems(state) {
      return state.cartProducts;  // Повертаємо список товарів у кошику
    },

    totalPrice(state) {
      let total = 0;
      state.cartProducts.forEach(item => {
        const priceToUse = item.discount_price > 0 ? item.discount_price : item.price;
        total += priceToUse * item.qty;
      });
      return total.toFixed(2);  // Обчислюємо загальну суму товарів з урахуванням кількості та знижки
    },
    cartItemCount(state) {
      return state.cartProducts.reduce((total, item) => total + item.qty, 0);  // Підраховуємо загальну кількість товарів у кошику
    }
    
  },
};
