import { createStore } from 'vuex';
import auth from './modules/auth';
import cart from './modules/cart';
import wishlist from './modules/wishlist';
import checkout from './modules/checkout';

const store = createStore({
  modules: {
    auth,
    cart,
    wishlist,
    checkout,
  },
});

export default store;
