<template>
  <div class="carousel-container _container"> 
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <slide v-for="product in products" :key="product.id" class="carousel-slide">
          <SliderItem :product="product" @sendId="handleSendId" @s_i_close_cart="SI_CloseCart" @s_i_close_wish="SI_CloseWish"/>
        </slide>
  
      <template #addons>
          <Navigation />
      </template>
      </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import instance from '@/api/axiosInstance';
import 'vue3-carousel/dist/carousel.css'
import SliderItem from '@/components/SliderItemComponent.vue';

export default defineComponent({
  name: 'SliderComponent',
  components: {
    Carousel,
    Slide,
    Navigation,
    SliderItem,
  },
  data: () => ({

    settings: {
      itemsToShow: 2,
      snapAlign: 'start',
    },
    breakpoints: {
      768: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
    products: null
  }),
  mounted() {
  instance.get('/api/v1/shop/best-products/')
    .then(response => {
      console.log('API response via instance:', response.data);
      this.products = response.data; // Встановлюємо дані продуктів
    })
    .catch(error => {
      console.error('Error fetching products via instance:', error);
    });
  },
  methods: {
    SI_CloseCart() {
      console.log("SI_cart")
      this.$emit('si_close_cart');
    },
    SI_CloseWish() {
      console.log("SI_wish")
      this.$emit('si_close_wish');
    },
    handleSendId(productId) {
      console.log('Product ID:', productId);
    },
  
  }
  
})
</script>

<style scoped>

.carousel-container {
overflow: hidden; 

}
.carousel-slide {
display: flex;
justify-content: center;
align-items: stretch;
box-sizing: border-box;
}

.product-card {
padding: 10px;
border: 1px solid #ddd;
border-radius: 8px;
text-align: center;
box-sizing: border-box;
width: 100%;
}

.product-image {
max-width: 100%;
border-radius: 8px;
}

.product-name {
margin: 10px 0;
font-size: 1.2rem;
}

.product-price {
color: #555;
}
</style>

