<template>
  <div class="contacts_page">
    <div class="img_block">
      <img :src="isMobile ? contact_img_mob : contact_img" alt="contact_img"/>
    </div>
    <div class="text_block">
      <div class="contact_text_cart">
        <div class="title_contact">
          ЛАБОРАТОРНІ ЕКСПЕРТИЗИ
          <br />
          ВИСОКА ЯКІСТЬ ОБСЛУГОВУВАННЯ
        </div>
        <div class="text_contac">
          <p>
            Є питання? Маєш сумніви? Потрібна допомога з оформленням замовлення чи вибором
            найкращого продукту для тебе? Зв’яжись з нами, ми з радістю тобі допоможемо.
          </p>
        </div>
        <ul class="links_contact">
          <li>
            <a href="https://www.instagram.com/nichebeautylab_ukraine" target="_blank">
              <img :src="isMobile ? insta2 : insta1" alt="Instagram" class="link_icon"/>
              /nichebeautylab_ukraine
            </a>
          </li>
          <li>
            <a href="https://t.me/Niche_beauty_lab" target="_blank">
              <img :src="isMobile ? tg2 : tg1" alt="Telegram" class="link_icon"/>
              /Niche_beauty_lab
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="video_block">
      <video :src="contact_video" autoplay muted loop playsinline></video>
    </div>
  </div>
</template>

<script>
import contact_video from '@/assets/img/video/Messages_Grey.mp4';
import contact_img from '@/assets/img/component/contacts_page_img.webp';
import contact_img_mob from '@/assets/img/component/Contact_Page_Ask_ContactUs_02.webp';
import insta1 from '@/assets/img/ico/insta1.svg';
import insta2 from '@/assets/img/ico/insta2.svg';
import tg1 from '@/assets/img/ico/tg1.svg';
import tg2 from '@/assets/img/ico/tg2.svg';

export default {
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  computed: {
    contact_video() {
      return contact_video;
    },
    contact_img() {
      return contact_img;
    },
    contact_img_mob() {
      return contact_img_mob;
    },
    insta1() {
      return insta1;
    },
    insta2() {
      return insta2;
    },
    tg1() {
      return tg1;
    },
    tg2() {
      return tg2;
    },
  },
};
</script>

<style scoped>
.contacts_page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
}

.img_block {
  width: 33%;
}

.img_block img {
  max-width: 100%;
  height: auto;
}

.contact_text_cart {
  padding: 100px;
}

.text_block {
  background-color: #d4eb59;
  padding: 20px;
  text-align: center;
  width: 33%;
}

.title_contact {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}

.text_contac p {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.links_contact {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.links_contact li {
  margin-bottom: 10px;
}

.links_contact a {
  font-size: 18px;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link_icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.video_block {
  width: 33%;
}

.video_block video {
  max-width: 100%;
}

@media (max-width: 768px) {
  .contacts_page {
    flex-direction: column;
  }

  .img_block,
  .text_block,
  .video_block {
    width: auto;
  }

  .text_block {
    margin-top: -50px;
    padding: 10px;
  }

  .text_block {
    background: #000;
    color: #fff;
    text-align: left;
    padding: 10px;
  }

  .contact_text_cart {
    padding: 0;
  }

  .text_contac {
    font-weight: 300;
  }

  .title_contact {
    font-weight: 300;
    margin-top: -90px;
  }

  .links_contact a {
    color: #fff;
    font-weight: 300;
  }

  .link_icon {
    background: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50px;
  }
}
</style>
