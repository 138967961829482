import Cookies from 'js-cookie';

export const setAuthToken = (token) => {
    Cookies.set('authToken', token, {
        expires: 7, 
        sameSite: 'Lax',
    });
};

// Функція для отримання токена з куки
export const getAuthToken = () => {
    return Cookies.get('authToken');
};

// Функція для видалення токена з куки
export const removeAuthToken = () => {
    Cookies.remove('authToken');
};
