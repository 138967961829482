<template>
  <div class="content">
    <div class="partner_data">
      <h1 class="center">Анкета партнера</h1>
      <form @submit.prevent="submitForm" class="form">
        <!-- Поле прізвище -->
        <div class="form-row">
          <div class="form-group">
            <input
              v-model="formData.last_name"
              @blur="validateField('last_name')"
              type="text"
              class="form-control"
              placeholder="Прізвище*"
              required
            />
            <span v-if="errors.last_name" class="error">{{ errors.last_name }}</span>
          </div>
          <div class="form-group">
            <input
              v-model="formData.first_name"
              @blur="validateField('first_name')"
              type="text"
              class="form-control"
              placeholder="Ім'я*"
              required
            />
            <span v-if="errors.first_name" class="error">{{ errors.first_name }}</span>
          </div>
        </div>

        <!-- Поле по-батькові та email -->
        <div class="form-row">
          <div class="form-group">
            <input
              v-model="formData.patronymic"
              @blur="validateField('patronymic')"
              type="text"
              class="form-control"
              placeholder="По-батькові*"
              required
            />
            <span v-if="errors.patronymic" class="error">{{ errors.patronymic }}</span>
          </div>
          <div class="form-group">
            <input
              v-model="formData.email"
              @blur="validateField('email')"
              type="email"
              class="form-control"
              placeholder="Email*"
              required
            />
            <span v-if="errors.email" class="error">{{ errors.email }}</span>
          </div>
        </div>

        <!-- Поле телефон та дата народження -->
        <div class="form-row">
          <div class="form-group">
            <input
              v-model="formData.phone_number"
              @blur="validateField('phone_number')"
              type="text"
              class="form-control"
              placeholder="+__(___)___-__-__*"
              required
            />
            <span v-if="errors.phone_number" class="error">{{ errors.phone_number }}</span>
          </div>
          <div class="form-group">
            <input
              v-model="formData.date_of_birth"
              @blur="validateField('date_of_birth')"
              type="date"
              placeholder="Введіть дату народження"
              class="form-control"
              required
            />
            <span v-if="errors.date_of_birth" class="error">{{ errors.date_of_birth }}</span>
          </div>
        </div>

        <!-- Вибір посади та освіти -->
        <div class="form-row">
          <div class="form-group">
            <label for="position">Посада:</label>
            <select v-model="formData.position" @blur="validateField('position')" class="form-control" required>
              <option value="" disabled selected>Виберіть із списку</option>
              <option v-for="position in positions" :key="position.id" :value="position.id">{{ position.name }}</option>
            </select>
            <span v-if="errors.position" class="error">{{ errors.position }}</span>
          </div>
          <div class="form-group">
            <label for="education">Освіта:</label>
            <select v-model="formData.education" @blur="validateField('education')" class="form-control" required>
              <option value="" disabled selected>Виберіть із списку</option>
              <option v-for="education in educations" :key="education.id" :value="education.id">{{ education.name }}</option>
            </select>
            <span v-if="errors.education" class="error">{{ errors.education }}</span>
          </div>
        </div>

        <!-- Поля спеціалізації та місця роботи -->
        <div class="form-row">
          <div class="form-group">
            <label for="specialization">Основна спеціалізація:</label>
            <select v-model="formData.specialization" @blur="validateField('specialization')" class="form-control" required>
              <option value="" disabled selected>Виберіть із списку</option>
              <option v-for="specialization in specializations" :key="specialization.id" :value="specialization.id">{{ specialization.name }}</option>
            </select>
            <span v-if="errors.specialization" class="error">{{ errors.specialization }}</span>
          </div>
          <div class="form-group">
            <label for="workplace">Місце роботи:</label>
            <select v-model="formData.workplace" @blur="validateField('workplace')" class="form-control" required>
              <option value="" disabled selected>Виберіть із списку</option>
              <option v-for="workplace in workplaces" :key="workplace.id" :value="workplace.id">{{ workplace.name }}</option>
            </select>
            <span v-if="errors.workplace" class="error">{{ errors.workplace }}</span>
          </div>
        </div>

        <!-- Поле назва клініки та адреса -->
        <div class="form-group">
          <input
            v-model="formData.clinic_name"
            @blur="validateField('clinic_name')"
            type="text"
            class="form-control"
            placeholder="Назва салону/клініки*"
            required
          />
          <span v-if="errors.clinic_name" class="error">{{ errors.clinic_name }}</span>
        </div>
        <div class="form-group">
          <input
            v-model="formData.address"
            @blur="validateField('address')"
            type="text"
            class="form-control"
            placeholder="Місто, вулиця, будинок, під'їзд, номер офісу*"
            required
          />
          <span v-if="errors.address" class="error">{{ errors.address }}</span>
        </div>

        <!-- Поле соцмережі та сайт клініки -->
        <div class="form-row">
          <div class="form-group">
            <input
              v-model="formData.professional_social_media"
              @blur="validateField('professional_social_media')"
              type="text"
              class="form-control"
              placeholder="Професійний акаунт у соцмережі"
            />
            <span v-if="errors.professional_social_media" class="error">{{ errors.professional_social_media }}</span>
          </div>
          <div class="form-group">
            <input
              v-model="formData.clinic_website"
              @blur="validateField('clinic_website')"
              type="text"
              class="form-control"
              placeholder="Сайт салону/клініки"
            />
            <span v-if="errors.clinic_website" class="error">{{ errors.clinic_website }}</span>
          </div>
        </div>

        <!-- Поле як дізнались -->
        <div class="form-group">
          <label for="how_they_heard_about_us">Звідки дізнались про нас?</label>
          <select v-model="formData.how_they_heard_about_us" @blur="validateField('how_they_heard_about_us')" class="form-control">
            <option value="" disabled selected>Виберіть із списку</option>
            <option v-for="item in how_they" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
          <span v-if="errors.how_they_heard_about_us" class="error">{{ errors.how_they_heard_about_us }}</span>
        </div>

        <!-- Поле завантаження сертифікату -->
        <div class="form-group">
          <label for="profile_certificate_photo">Фото сертифікату:</label>
          <input
            type="file"
            id="profile_certificate_photo"
            @change="handleFileUpload"
            class="form-control file-input"
            accept="image/*"
            @blur="validateField('profile_certificate_photo')"
          />
          <span v-if="errors.profile_certificate_photo" class="error">{{ errors.profile_certificate_photo }}</span>
        </div>

        <!-- Кнопка відправки -->
        <button type="submit" enctype="multipart/form-data" class="btn btn-primary">Відправити</button>
      </form>
    </div>
  </div>
</template>

<script>
import instance from "@/api/axiosInstance";

export default {
  data() {
    return {
      formData: {
        last_name: "",
        first_name: "",
        patronymic: "",
        email: "",
        phone_number: "",
        date_of_birth: "",
        position: "",
        education: "",
        specialization: "",
        workplace: "",
        clinic_name: "",
        address: "",
        professional_social_media: "",
        clinic_website: "",
        brands_worked_with: "",
        how_they_heard_about_us: "",
        profile_certificate_photo: null,
      },
      errors: {},
      positions: [],
      educations: [],
      specializations: [],
      workplaces: [],
      how_they: [],
    };
  },
  mounted() {
    this.getFormOptions();
  },
  methods: {
    getFormOptions() {
      instance.get('/api/v1/actions/partner/')
        .then((response) => {
          this.positions = response.data.positions;
          this.educations = response.data.educations;
          this.specializations = response.data.specializations;
          this.workplaces = response.data.workplaces;
          this.how_they = response.data.how_they;
        })
        .catch((error) => {
          console.error("Error fetching form options:", error);
        });
    },
    validateField(field) {
      const value = this.formData[field];
      switch (field) {
        case "last_name":
        case "first_name":
        case "patronymic":
          this.errors[field] = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ'’\s-]+$/.test(value) && value.trim().length >= 2
            ? ""
            : "Введіть коректне значення (мінімум 2 символи)";
          break;
        case "email":
          this.errors[field] = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Введіть коректний email";
          break;
        case "phone_number":
          this.errors[field] = /^\+?(\d{2})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{2}[-. ]?\d{2}$/.test(value)
            ? ""
            : "Невірний формат телефону";
          break;
        case "date_of_birth":
          // eslint-disable-next-line 
          const age = this.calculateAge(new Date(value));
          this.errors[field] = age >= 18 ? "" : "Вам має бути більше 18 років";
          break;
        case "position":
        case "education":
        case "specialization":
        case "workplace":
        case "how_they_heard_about_us":
          this.errors[field] = value ? "" : "Це поле обов'язкове";
          break;
        case "clinic_name":
        case "address":
          this.errors[field] = value.trim().length >= 5 ? "" : "Введіть коректну назву або адресу";
          break;
        case "clinic_website":
        case "professional_social_media":
          this.errors[field] = /^(https?:\/\/)?([a-z0-9.-]+\.[a-z]{2,})(\/\S*)?$/.test(value)
            ? ""
            : "Невірний формат URL";
          break;
        case "profile_certificate_photo":
          this.errors[field] = this.formData.profile_certificate_photo ? "" : "Завантажте сертифікат";
          break;
      }
    },
    calculateAge(birthDate) {
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    formatUrl(field) {
      const url = this.formData[field];
      if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
        this.formData[field] = `https://${url}`;
      }
      this.validateField(field);
    },
    handleFileUpload(event) {
      if (event.target.files.length > 0) {
        this.formData.profile_certificate_photo = event.target.files[0];
        this.validateField("profile_certificate_photo");
      }
    },
    submitForm() {
      // Валідуємо всі поля перед відправкою
      for (const field in this.formData) {
        this.validateField(field);
      }

      if (Object.values(this.errors).some((error) => error)) {
        alert("Будь ласка, виправте помилки у формі.");
        return;
      }

      let formData = new FormData();
      for (let key in this.formData) {
        formData.append(key, this.formData[key]);
      }

      instance.post('/api/v1/actions/partner/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          alert(response.data.success);
          this.resetForm();
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          alert("Помилка при відправці форми.");
        });
    },
    resetForm() {
      this.formData = {
        last_name: "",
        first_name: "",
        patronymic: "",
        email: "",
        phone_number: "",
        date_of_birth: "",
        position: "",
        education: "",
        specialization: "",
        workplace: "",
        clinic_name: "",
        address: "",
        professional_social_media: "",
        clinic_website: "",
        brands_worked_with: "",
        how_they_heard_about_us: "",
        profile_certificate_photo: null,
      };
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}
/* Ваші існуючі стилі */
.content {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.partner_data {
  width: 100%;
  max-width: 800px;
}
.partner_data h1 {
  margin-bottom: 35px;
}
.form {
  display: grid;
  gap: 15px;
  width: calc(100% - 24px);
}
.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form-group {
  margin-bottom: 10px;
}
.form-control {
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.form-control option {
  color: #000;
}
.btn {
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  width: 100%;
  letter-spacing: 1px;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px;
  font-family: 'MaisonNeue';
  margin-bottom: 5px;
}
.btn:hover {
  background-color: #000;
  color: #fff;
}
.file-input {
  display: block;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
@media (max-width: 600px) {
  .form {
    width: 100%;
  }
  .form-row {
    grid-template-columns: 1fr;
  }
}
.form-row .form-group {
  width: 100%;
}
.form input,
.form textarea {
  font-size: var(--h5);
  width: calc(100% - 26px);
  border: 2px solid #f5f5f5;
  border-radius: 4px;
  padding: 10px 14px;
}
.selected-field {
  background-color: black;
  color: white;
}
.form-group select {
  width: 101%;
}
</style>
