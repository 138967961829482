<template>
    <div class="login-container">
        <div class="login-form">
            <div class="success_data">            
                <img :src="success" alt="success" />
            </div>

            <h1>Успішна реєстрація!</h1>
            <p>Дякуємо за реєстрацію. Будь ласка, перевірте свою електронну пошту та активуйте свій акаунт, перейшовши за посиланням, яке ми надіслали.</p>
        </div>
    </div>
</template>
  
  <script>
  import success from '@/assets/img/ico/success3.svg';

  export default {
    name: "RegistrationSuccess",
    computed: {
        success() {
      return success;
    },
  }};
  </script>

<style scoped>
.login-form {
    text-align: center;
}
.success_data img{
    width:300px;
    height:auto;
}
</style>

<style src="@/assets/css/Login.css"></style>