<template>
    <div class="index-section _container">
        <div class="container p-0 mb-3">
            <a href="/contacts">
                <picture class="rte__image">
                    <source media="(max-width: 480px)" srcset="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/CONTACT_PAGE_ASK_CONTACTUS_SINPASTILLA.jpg?v=1648646036">
                    <img class="img-fluid" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/CONTACT_PAGE_ASK_DESKTOP.jpg?v=1648646036" alt="Ask/Contact">
                </picture>
            </a>
        </div>
    </div>
</template>

<style>
    .rte__image img{ 
        max-width: 100%;
    }
</style>