<template>
    <div class="login-container">
      <form @submit.prevent="handleSubmit" class="login-form">
        <h2>Скидання Паролю</h2>
        <p>Введіть новий пароль.</p>
  
        <!-- Поле Новий Пароль -->
        <div class="form-group password-group">
          <div class="password-input-wrapper">
            <input 
              :type="showNewPassword ? 'text' : 'password'" 
              id="newPassword" 
              v-model="newPassword" 
              required 
              placeholder="Введіть новий пароль" 
            />
            <span class="toggle-password" @click="toggleNewPassword">
              <svg v-if="!showNewPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
                <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8a21.18 21.18 0 0121.18-21.18"></path>
                <path d="M1 1l22 22"></path>
                <path d="M10.58 10.58a3 3 0 104.24 4.24"></path>
              </svg>
            </span>
          </div>
          <!-- Валідаційні повідомлення для нового паролю -->
          <div v-if="newPasswordErrors.length" class="error-message">
            <ul>
              <li v-for="(error, index) in newPasswordErrors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
  
        <!-- Поле Підтвердження Паролю -->
        <div class="form-group password-group">
          <div class="password-input-wrapper">
            <input 
              :type="showConfirmNewPassword ? 'text' : 'password'" 
              id="confirmPassword" 
              v-model="confirmPassword" 
              required 
              placeholder="Підтвердження нового паролю" 
            />
            <span class="toggle-password" @click="toggleConfirmNewPassword">
              <svg v-if="!showConfirmNewPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
                <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8a21.18 21.18 0 0121.18-21.18"></path>
                <path d="M1 1l22 22"></path>
                <path d="M10.58 10.58a3 3 0 104.24 4.24"></path>
              </svg>
            </span>
          </div>
        </div>
  
        <!-- Кнопка Змінити Пароль -->
        <button type="submit" class="login-button">
          Змінити Пароль
        </button>
  
        <!-- Повідомлення про помилки -->
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
      </form>
    </div>
  </template>
  
  <script>
  import instance from '@/api/axiosInstance';
  
  export default {
    name: "ResetPasswordConfirm",
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
        errorMessage: '',
        successMessage: '',
        uid: '',
        token: this.$route.params.token,
        showNewPassword: false,
        showConfirmNewPassword: false,
        newPasswordErrors: [],
      };
    },
  
    created() {
      const uid = this.$route.params.uid;
      if (uid) {
        this.uid = uid;
      } else {
        this.errorMessage = 'UID не знайдено в URL.';
      }
    },
  
    methods: {
      toggleNewPassword() {
        this.showNewPassword = !this.showNewPassword;
      },
      toggleConfirmNewPassword() {
        this.showConfirmNewPassword = !this.showConfirmNewPassword;
      },
      validatePassword(password) {
        const errors = [];
        if (password.length < 8) {
          errors.push('Пароль занадто короткий. Мінімум 8 символів.');
        }
        if (!/[A-Z]/.test(password)) {
          errors.push('Пароль повинен містити хоча б одну велику літеру.');
        }
        if (!/[a-z]/.test(password)) {
          errors.push('Пароль повинен містити хоча б одну малу літеру.');
        }
        if (!/[0-9]/.test(password)) {
          errors.push('Пароль повинен містити хоча б одну цифру.');
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
          errors.push('Пароль повинен містити принаймні один спеціальний символ.');
        }
        return errors;
      },
      async handleSubmit() {
        this.newPasswordErrors = this.validatePassword(this.newPassword);
        if (this.newPasswordErrors.length) {
          return; // Вийти, якщо є помилки валідації
        }
        if (this.newPassword !== this.confirmPassword) {
          this.errorMessage = 'Паролі не збігаються.';
          return;
        }
  
        try {
          const response = await instance.post('/api/v1/users/reset_password_confirm/', {
            uid: this.uid,
            token: this.token,
            new_password: this.newPassword,
          }, {
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
          if (response.status === 204) {
            this.successMessage = 'Пароль успішно змінено!';
            setTimeout(() => {
              this.$router.push({ name: 'Login' });
            }, 2000);
          } else {
            this.errorMessage = 'Зміна пароля не вдалася. Спробуйте ще раз.';
          }
        } catch (error) {
          this.errorMessage = 'Сталася помилка. Спробуйте ще раз.';
          console.error(error);
        }
      },
    }
  };
  </script>
  
  <style src="@/assets/css/Login.css"></style>
  )