<template>
    <div>
      <h1>Підтвердження платежу</h1>
    
      <!-- Анімація завантаження на всю сторінку, поки триває обробка -->
      <div v-if="loading || isPending" class="overlay">
        <p>Очікування підтвердження оплати для замовлення: {{ $route.params.order_reference }}</p>
        <div class="loader"></div> <!-- Анімація завантаження -->
      </div>
  
      <!-- Сповіщення про успішну оплату -->
      <div v-if="paymentStatus === 'Approved'" class="success-message">
        <p>Оплата пройшла успішно! Ваше замовлення підтверджено.</p>
      </div>
  
      <!-- Сповіщення про невдалу оплату -->
      <div v-if="paymentStatus === 'Declined' || paymentStatus === 'failed'" class="error-message">
        <p>Оплата не пройшла. Якщо з вас зняли кошти, будь ласка, зверніться до менеджера.</p>
      </div>
    </div>
  </template>
  
  <script>
  import instance from '@/api/axiosInstance'; 
  
  export default {
    name: 'PaymentConfirmation',
    data() {
      return {
        paymentStatus: null,
        loading: true,
        isPending: true,  // Прапорець для перевірки, чи триває очікування
        intervalId: null,   // ID інтервалу для періодичних запитів
        apiBaseUrl: process.env.VUE_APP_API_BASE_URL  // Отримуємо базовий URL з .env
      };
    },
    methods: {
      async checkPaymentStatus() {
        try {
          console.log("Перевірка статусу для:", this.$route.params.order_reference);
          
          // Викликаємо callback для оновлення статусу платежу
          await instance.post(`${this.apiBaseUrl}api/v1/orders/payment/callback/`, {
            payment_reference: this.$route.params.order_reference
          });
  
          // Запит на перевірку статусу платежу
          const response = await instance.post(`${this.apiBaseUrl}api/v1/orders/check_pay/`, {
            payment_reference: this.$route.params.order_reference
          });
          
          const paymentStatus = response.data.status; // Отримуємо статус із відповіді бекенда
          this.paymentStatus = paymentStatus;
          this.loading = false; // Зупиняємо анімацію
  
          if (paymentStatus === 'Approved') {
            this.stopPolling(); // Зупиняємо цикл запитів
            this.loading = false;
            // Переходимо на сторінку успіху
            this.$router.push('/confirmation');
          } else if (['Declined', 'failed'].includes(paymentStatus)) {
            this.stopPolling(); // Зупиняємо цикл запитів
            this.loading = false;
            // Переходимо на сторінку невдачі
            this.$router.push('/failed');
          } else if (['Pending', 'InProcessing', 'WaitingAuthComplete'].includes(paymentStatus)) {
            this.isPending = true; // Продовжуємо цикл запитів
          } else {
            this.stopPolling(); // Зупиняємо цикл у разі невідомого статусу
            this.$router.push('/failed'); // Переходимо на сторінку невдачі
          }
        } catch (error) {
          console.error('Помилка при перевірці статусу платежу:', error);
          this.stopPolling(); // Зупиняємо цикл у разі помилки
          this.$router.push('/failed'); // Переходимо на сторінку невдачі
        }
      },
      startPolling() {
        // Періодичні запити кожні 5 секунд
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            this.checkPaymentStatus(); // Перевіряємо статус оплати
          }, 5000); // 5 секунд
        }
      },
      stopPolling() {
        // Зупиняємо періодичні запити
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    },
    mounted() {
      // Починаємо періодичну перевірку після завантаження сторінки
      this.startPolling();
    },
    beforeUnmount() {
      // Очищаємо інтервал перед знищенням компонента
      this.stopPolling();
    }
  };
  </script>
  
  
  <style scoped>
  /* Анімація завантаження */
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Стиль для накладання анімації на всю сторінку */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: white;
    text-align: center;
  }
  
  /* Сповіщення про успішну оплату */
  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border-radius: 5px;
    margin: 20px;
    text-align: center;
  }
  
  /* Сповіщення про невдалу оплату */
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 5px;
    margin: 20px;
    text-align: center;
  }
  </style>
  