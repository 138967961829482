<template>
  <div>
    <h4>Виберіть спосіб оплати:</h4>
    <div class="payment-options">
      <button 
        class="payment-button" 
        :class="{ active: paymentMethod === 'full' }" 
        @click="selectPaymentMethod('full')"
        :disabled="!canSelectPayment"
      >
        Повна оплата карткою
      </button>
      <button 
        class="payment-button" 
        :class="{ active: paymentMethod === 'prepayment' }" 
        @click="selectPaymentMethod('prepayment')"
        :disabled="!canSelectPayment"
      >
        Передоплата 200 грн
      </button>
    </div>

    <div class="nova-poshta-delivery">
      <h4>Нова Пошта</h4>
      
      <!-- Поле для пошуку міста -->
      <label>Населений пункт:</label>
      <input 
        v-model="cityInput" 
        type="text" 
        placeholder="Введіть населений пункт" 
        @input="debouncedSearchCity" 
        required 
        :class="{ 'input-error': errors.city }"
      />
      <ul v-if="filteredCities.length" class="dropdown">
        <li v-for="(city, index) in filteredCities" :key="index" @click="selectCity(city)">
          {{ city.settlement_description }}
        </li>
      </ul>
      <span v-if="errors.city" class="error-message">Поле "Населений пункт" обов'язкове</span>

      <!-- Поле для пошуку відділення -->
      <label>Номер відділення або поштомату:</label>
      <input 
        v-model="branchInput" 
        type="text" 
        placeholder="Введіть номер відділення або поштомату" 
        @input="debouncedSearchBranch" 
        required 
        :class="{ 'input-error': errors.branch }"
      />
      <ul v-if="filteredBranches.length" class="dropdown">
        <li v-for="(branch, index) in filteredBranches" :key="index" @click="selectBranch(branch)">
          {{ branch.number }} - {{ branch.description }}
        </li>
      </ul>
      <span v-if="errors.branch" class="error-message">Поле "Номер відділення" обов'язкове</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import instance from '@/api/axiosInstance';
import debounce from 'lodash.debounce';

export default {
  data() {
    return {
      cityInput: '',
      branchInput: '',
      selectedCity: null,
      selectedBranch: null,
      paymentMethod: null,
      filteredCities: [],
      filteredBranches: [],
      errors: {
        city: false,
        branch: false,
      },
      loadingCities: false,
      loadingBranches: false,
      errorCities: null,
      errorBranches: null,
      debouncedSearchCity: null,
      debouncedSearchBranch: null,
    };
  },
  computed: {
    canSelectPayment() {
      return this.selectedCity && this.selectedBranch;
    }
  },
  created() {
    // Ініціалізація дебаунсінгу
    this.debouncedSearchCity = debounce(this.searchCity, 300);
    this.debouncedSearchBranch = debounce(this.searchBranch, 300);
  },
  methods: {
    ...mapActions('checkout', ['saveDeliveryInfo', 'savePaymentMethod']),
    
    // Пошук населеного пункту
    async searchCity() {
      const cityQuery = this.cityInput.trim();
      if (cityQuery.length >= 2) {
        this.loadingCities = true;
        this.errorCities = null;
        try {
          const response = await instance.get('/api/v1/np/search/city/', { params: { city: cityQuery } });
          if (response.data.message === 'Success') {
            this.filteredCities = response.data.input_value;
          } else {
            this.filteredCities = [];
            this.errorCities = response.data.message;
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
          this.errorCities = 'Сталася помилка при пошуку населеного пункту.';
          this.filteredCities = [];
        } finally {
          this.loadingCities = false;
        }
      } else {
        this.filteredCities = [];
      }
    },

    // Пошук відділення
    async searchBranch() {
      const branchQuery = this.branchInput.trim();
      if (branchQuery.length >= 1 && this.selectedCity) {
        this.loadingBranches = true;
        this.errorBranches = null;
        try {
          const response = await instance.get('/api/v1/np/search/post/', { 
            params: { 
              city: `${this.selectedCity.settlement_description}, ${this.selectedCity.settlement_regions_description}, ${this.selectedCity.settlement_area_description}`,
              post: branchQuery 
            } 
          });
          if (response.data.message === 'Success') {
            this.filteredBranches = response.data.input_value;
          } else {
            this.filteredBranches = [];
            this.errorBranches = response.data.message;
          }
        } catch (error) {
          console.error('Error fetching branches:', error);
          this.errorBranches = 'Сталася помилка при пошуку відділення.';
          this.filteredBranches = [];
        } finally {
          this.loadingBranches = false;
        }
      } else {
        this.filteredBranches = [];
      }
    },

    selectCity(city) {
      this.selectedCity = city;
      this.cityInput = city.settlement_description;
      this.filteredCities = [];
      this.updateNovaPoshtaInfo();
      // Скидання вибраного відділення при зміні міста
      this.selectedBranch = null;
      this.branchInput = '';
      this.filteredBranches = [];
      this.errors.branch = false;
    },

    selectBranch(branch) {
      this.selectedBranch = branch;
      this.branchInput = branch.number;
      this.filteredBranches = [];
      this.updateNovaPoshtaInfo();
    },

    updateNovaPoshtaInfo() {
      this.errors.city = !this.selectedCity;
      this.errors.branch = !this.selectedBranch;
      if (this.selectedCity && this.selectedBranch) {
        this.saveDeliveryInfo({
          city: `${this.selectedCity.settlement_description}, ${this.selectedCity.settlement_area_description}, ${this.selectedCity.settlement_regions_description}`,
          branch: this.selectedBranch.number
        });
      }
    },

    selectPaymentMethod(method) {
      this.paymentMethod = method;
      this.savePaymentMethod(method);
    }
  },
  beforeUnmount() {
    // Перевірка чи функція дебаунсінгу існує перед її скасуванням
    if (this.debouncedSearchCity && this.debouncedSearchCity.cancel) {
      this.debouncedSearchCity.cancel();
    }
    if (this.debouncedSearchBranch && this.debouncedSearchBranch.cancel) {
      this.debouncedSearchBranch.cancel();
    }
  }
};
</script>

<style scoped>
/* Додаємо стилі для випадаючого списку */
.dropdown {
  background-color: white;
  border: 1px solid #ccc;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  width: 95%;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f1f1f1;
}

.nova-poshta-delivery {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative; /* Для правильного позиціонування випадаючих списків */
}

.nova-poshta-delivery label {
  font-weight: bold;
}

.nova-poshta-delivery input {
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 1rem;
}

.payment-options {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.payment-button {
  text-transform: uppercase;
  background: #fff;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  width: 100%;
  letter-spacing: 1px;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px;
  font-family: 'MaisonNeue';
  margin-bottom: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.payment-button:disabled {
  background-color: #ddd;
  color: #999;
  border-color: #ddd;
  cursor: not-allowed;
}

.payment-button.active {
  background-color: #000;
  color: #fff;
  border-color: #000;
}

/* Стилі для полів з помилками */
.input-error {
  border-color: red;
}

/* Стилі для повідомлень про помилки */
.error-message {
  color: red;
  font-size: 0.875rem;
}
</style>
