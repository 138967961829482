<template>
    <div class="collection-home _container">
        
        <div class="collection-home-title">
            <h2>бренди</h2>
        </div> 
        <div class="collection-home-items">
            <div class="content-brand-info">
                <a href="/collection/theramid">
                    <picture class="rte__image">
                        <source media="(max-width: 480px)" srcset="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/theramid_home_brand_mobile.png?v=1703229691">
                        <img class="img-fluid" loading="lazy" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/theramid_home_brand.png?v=1703174055" alt="Thereamid Banner">
                </picture>
                </a>
                <div class="text-brands">
                    <a href="/collection/theramid" class="logo-brand-text rte__image">
                        <img class="img-fluid logo-brand-img-tm" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/theramid_logo.svg?v=1648798669" alt="Acnemy Logo" width="220px" height="45px">
                    </a>
                    <a href="/collection/theramid" loading="lazy" class="inline-link">Дізнатися більше</a>
                </div>
            </div>

            <div class="content-brand-info">
                <a href="/collection/acnemy">
                    <picture class="rte__image">
                        <source media="(max-width: 480px)" srcset="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/acnemy_home_brand_mobile.png?v=1703229692">
                        <img class="img-fluid" loading="lazy" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/acnemy_home_brand.png?v=1703174055" alt="Acnemy Banner">
                </picture>
                </a>
                <div class="text-brands">
                    <a href="/collection/acnemy" class="logo-brand-text rte__image">
                        <img class="img-fluid logo-brand-img-ac" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/acnemy.svg?v=1646823118" alt="Acnemy Logo" width="220px" height="45px">
                    </a>
                    <a href="/collection/acnemy" loading="lazy" class="inline-link">Дізнатися більше</a>
                </div>
            </div>

            <div class="content-brand-info">
                <a href="/collection/transparent-lab">
                    <picture class="rte__image">
                        <source media="(max-width: 480px)" loading="lazy"  srcset="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/transparent-lab_home_brand_mobile.png?v=1703229691">
                        <img class="img-fluid" loading="lazy" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/transparent-lab_home_brand.png?v=1703174055" alt="Transparent Lab Banner">
                </picture>
                </a>
                <div class="text-brands">
                    <a href="/collection/transparent-lab" class="logo-brand-text rte__image">
                        <img class="img-fluid logo-brand-img-tl" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/transparent_logo.svg?v=1646823118" alt="Transparent La Logo" width="220px" height="45px">
                    </a>
                    <a href="/collection/transparent-lab"  class="inline-link">Дізнатися більше</a>
                </div>
            </div>

        </div>
    </div>
</template>


<style scoped>

.collection-home-title h2 {
    line-height: normal;
    font-size: 20px;
    margin: 1.5rem 0;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    letter-spacing: 5px;
}
.collection-home-items { 
    display: flex;
}
@media (max-width: 768px) {
    .collection-home-items {
        flex-wrap: wrap;
    }
}
.content-brand-info {
    width: 33%;
    padding: 5px;
    margin: 0.5rem 0;
    text-align: center;
    position: relative;
}
@media (max-width: 768px) {
    .content-brand-info {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}
.content-brand-info img {
    max-width: 100%;
}
.text-brands { 
    position: absolute;
    width: 100%;
    right: 0;
    padding: 0 10px;
    font-weight: 600;
    top: 40%;
    left: 0;
    text-align: center;
}
.text-brands .logo-brand-text {
    margin-bottom: 10px !important;
    display: block;
    text-align: center;
}
.inline-link {
    text-decoration: underline;
}
</style>