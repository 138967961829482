<template>
    <div class="not-found">
      <h1>404 - Сторінку не знайдено</h1>
      <p>На жаль, ми не можемо знайти сторінку, яку ви шукаєте.</p>
      <router-link to="/">Повернутись на головну</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
  };
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    padding: 50px;
    min-height: 650px;
  }
  
  .not-found h1 {
    font-size: 4em;
    margin-bottom: 20px;
  }
  
  .not-found p {
    font-size: 1.5em;
    margin-bottom: 30px;
  }
  
  .not-found a {
    font-size: 1.2em;
    color: #2a2c2b;
  }
  .not-found a:hover {
    font-size: 1.4em;

  }

  </style>
  