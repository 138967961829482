<template>
    <div>
      <h2>Activating your account...</h2>
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import instance from '@/api/axiosInstance';
  
  export default {
    data() {
      return {
        errorMessage: null,
      };
    },
    created() {
      const uid = this.$route.params.uid;
      const token = this.$route.params.token;
  
      this.activateUser(uid, token);
    },
    methods: {
      async activateUser(uid, token) {
        try {
          const response = await instance.post('/api/v1/users/activation/', {
            uid,
            token
          }, {
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
          // Якщо статус відповіді 204, це означає успішну активацію
          if (response.status === 204) {
            this.$router.push({ name: 'Login' }); // Редірект на сторінку логіну
          } else {
            // Можливо інший код відповіді, тоді обробка помилки
            this.errorMessage = 'Activation failed. Please try again.';
          }
        } catch (error) {
          this.errorMessage = 'An error occurred. Please try again.';
        }
      }
    }
  };
  </script>
  