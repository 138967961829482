<template>
  <div class="login-container">
    <form @submit.prevent="handleSubmit" class="login-form">
      <h2>Відновлення Паролю</h2>
      <p>Для відновлення пароля, введіть Ваш e-mail. Після чого ми відправимо Вам посилання для води нового пароля.</p>

      <!-- Поле Email для відновлення паролю -->
      <div class="form-group" v-if="!token">
        <input 
          type="email" 
          id="email" 
          v-model="email" 
          required 
          placeholder="Введіть ваш email" 
        />
      </div>

      <!-- Кнопка Відновити Пароль -->
      <button type="submit" class="login-button">
        {{ token ? 'Скинути Пароль' : 'Відновити Пароль' }}
      </button>

      <!-- Посилання на вхід -->
      <div class="form-links" v-if="!token">
        <router-link to="/login">Згадали пароль?</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import instance from '@/api/axiosInstance';

export default {
  name: "ResetPassword",
  data() {
    return {
      email: '',  // Поле для збереження email
      token: false, // Додано: якщо є токен - показувати інші поля
      errorMessage: '', // Поле для зберігання повідомлення про помилку
    };
  },

  methods: {
    async handleSubmit() {
      // Прінт в консоль введеного email
      console.log(this.email);
      // Логіка для відправки email для відновлення паролю
      try {
        const response = await instance.post('/api/v1/users/reset_password/', {
          email: this.email, // Використовуємо email з форми
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        // Якщо статус відповіді 204, це означає успішну активацію
        if (response.status === 204) {
          this.$router.push('/reset-password-success/' ); // Редірект на сторінку логіну
        } else {
          // Можливо інший код відповіді, тоді обробка помилки
          this.errorMessage = 'Reset failed. Please try again.';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred. Please try again.';
        console.error(error); // Логуємо помилку для відладки
      }
    },
  }
};
</script>

<style scoped>
.login-form p {
  font-size: 14px;
  color: grey;
  padding: 1rem 0;
}
</style>

<style src="@/assets/css/Login.css"></style>
