<template>
  <div class="product_cards _container">
    <div class="products" v-for="(product, productIndex) in products" :key="productIndex">
        <ProductCart :product="product"  @pc_close_cart="PCs_CloseCart" @pc_close_wish="PCs_CloseWish"/>
    </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue';
import ProductCart from '@/components/ProductCart.vue';

export default defineComponent({
  name: 'ProductCardsComponent',
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  components: {
    ProductCart,
  },
  methods: {
    PCs_CloseCart() {
      this.$emit('pc_s_close_cart');
    },
    PCs_CloseWish() {
      this.$emit('pc_s_close_wish');
    },
  
  }
});
</script>

<style scoped>
  .product_cards {
    display: flex;
    flex-wrap: wrap;
  }
  .products {
    height: 100%;
    flex: 0 0 25%;
  }
  @media (max-width: 992px) {
    .products {
      width: 306px;
    }
    ._container {
      width: 95%;
    }
  }
  @media (max-width: 768px) {
    .products {
      flex: 0 0 49%;
  }}
</style>