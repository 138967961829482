<template>
    <div id="checkout-overlay" style="display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 1000;">
      <div id="checkout-loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <p style="color: white;">Очікування оплати...</p>
        <div class="loader"></div>
      </div>
    </div>
  
    <div class="checkout-steps">
      <div class="steps-content">
        <PersonalInfo v-if="currentStep === 1" @nextStep="nextStep" />
        <DeliveryOptions v-if="currentStep === 2" />
      </div>
  
      <div class="steps-actions">
        <button v-if="currentStep > 1" @click="prevStep">Назад</button>
        <!-- Кнопка "Замовити" або "Оплатити" залежно від типу оплати -->
        <button v-if="currentStep > 1 && deliveryType && paymentMethod" @click="handleSubmit">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import PersonalInfo from './ShippingComponents/PersonalInfo.vue';
  import DeliveryOptions from './ShippingComponents/DeliveryOptions.vue';
  import { mapState, mapActions } from 'vuex';
  // eslint-disable-next-line
  import instance from '@/api/axiosInstance';  // Імпортуємо axios інстанс
  export default {
  name: 'CheckoutSteps',
  components: {
    PersonalInfo,
    DeliveryOptions
  },
  data() {
    return {
      currentStep: 1, // Поточний крок
    };
  },
  computed: {
    ...mapState('checkout', ['deliveryType', 'paymentMethod']),
    buttonText() {
      return this.paymentMethod === 'cash' ? 'Замовити' : 'Оплатити';
    },
  },
  methods: {
    ...mapActions('checkout', ['createOrder', 'saveTotalSum']),
    nextStep() {
      this.currentStep++; // Переходимо на наступний крок
    },
    prevStep() {
      this.currentStep--; // Повертаємося на попередній крок
    },
    async handleSubmit() {
      try {
        const order = await this.createOrder();  // Спочатку створюємо замовлення
        console.log('Замовлення створено:', order); // Додатковий лог для перевірки

        if (!order || !order.order_id) {
          throw new Error('Не вдалося створити замовлення');
        }


        if (this.paymentMethod === 'cash') {
          // Якщо метод оплати - готівка, редіректимо на сторінку підтвердження
          this.$router.push(`/confirmation`);
        } else {

          const orderReference = order.payment_data.orderReference;
          // Якщо метод оплати - карта, використовуємо payment_data, який прийшов із бекенду
          const paymentData = order.payment_data;

          if (!paymentData) {
            throw new Error('Не вдалося отримати дані для оплати');
          }

          // Показуємо анімацію очікування оплати
          this.showPaymentProcessing();
          console.log(paymentData);

          // Викликаємо віджет WayForPay для оплати
          this.initiatePayment(paymentData, orderReference);
        }
      } catch (error) {
        console.error('Помилка під час створення замовлення або ініціалізації оплати:', error);
        alert('Не вдалося створити замовлення або ініціювати оплату. Спробуйте знову.');
      }
    },

    initiatePayment(paymentData, orderReference) {
      // eslint-disable-next-line
      const wayforpay = new Wayforpay();

      wayforpay.run(
        paymentData,
        (response) => {
          // Обробка успішної оплати
          console.log('Оплата успішна:', response);
          this.redirectToPaymentConfirmation(orderReference);
        },
        (response) => {
          // Обробка відхиленої оплати
          console.error('Оплата не пройшла:', response);
          this.redirectToPaymentConfirmation(orderReference);
        },
        (response) => {
          // Платіж у процесі обробки
          console.log('Платіж обробляється:', response);
          this.redirectToPaymentConfirmation(orderReference);
        }
      );
    },

    redirectToPaymentConfirmation(orderReference) {
      // Виконуємо редірект на сторінку підтвердження
      this.$router.push(`/payment-confirmation/${orderReference}`);
    },

    // Функція для відображення статусу "в процесі"
    showPaymentProcessing() {
      // Показуємо екран очікування
      document.querySelector('#checkout-overlay').style.display = 'block';
      document.querySelector('#checkout-loader').style.display = 'block';
    },

    // Функція для приховування екрану очікування
    hidePaymentProcessing() {
      document.querySelector('#checkout-overlay').style.display = 'none';
      document.querySelector('#checkout-loader').style.display = 'none';
    },
  },
};

</script>
  
  <style scoped>
    .steps-content {
    display: flex;
    justify-content: space-around;
    /* width: calc(100% - 60px); */
    }
    .steps-actions {
        margin: 20px;
        display: flex;
        justify-content: center;
    }

    .steps-actions button {
      text-transform: uppercase;
      background: #ffffff;
      border: 1px solid #000;
      cursor: pointer;
      transition: 0.35s;
      width: 40%;
      letter-spacing: 1px;
      color: #000;
      font-weight: 600;
      font-size: 12px;
      padding: 10px 15px;
      font-family: 'MaisonNeue';
      margin-bottom: 5px;
      transition: background-color 0.3s, color 0.3s;
    }
    .steps-actions button:hover {
      background-color: #000;
      color: #aaa;
    }
  
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }
  
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  </style>
  