<template>
  <div class="placeholder-page">
    <h1>Ми тимчасово на технічному обслуговуванні</h1>
    <p>Шановні клієнти, дякуємо вам за вашу довіру та вибір нашого сайту! Наразі ми проводимо планові технічні роботи для покращення нашого сервісу та забезпечення ще кращого досвіду користування.

Ми дуже скоро відновимо роботу, і ви зможете знову користуватися всіма функціями нашого сайту. Дякуємо за ваше терпіння і розуміння.

Слідкуйте за новинами – ми повернемося зовсім скоро!

З найкращими побажаннями,
Команда Niche Beauty Lab</p>
    <router-link to="/login" class="login-link">Увійти</router-link>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderPage',
};
</script>

<style scoped>
.placeholder-page {
  text-align: center;
  margin-top: 50px;
}

.placeholder-page h1 {
  font-size: 2.5rem;
  color: #ff0000; /* Колір тексту заголовка */
}

.placeholder-page p {
  font-size: 1.2rem;
  margin: 20px 0;
}

.login-link {
  font-size: 1.2rem;
  color: #007BFF;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
  color: #0056b3;
}
</style>
