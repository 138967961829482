<template>
    <div class="order-confirmation">
      <h2>Замовлення не оплачено!</h2>
      <p>Напишіть нам, або дочекайтесь менеджера для уточнення детайлей.</p>
      
      <!-- Виводимо компоненти з кошиком -->
      <FeedbackComponent />
  
      <div class="contact-info">
        <p>Якщо у вас є питання, зв'яжіться з нами.</p>
      </div>
    </div>
  </template>
  
  <script>
  import FeedbackComponent from '@/components/FeedbackComponent.vue'; // Імпортуємо компонент CartSummary

  export default {
    name: 'OrderConfirmation',
    components: {
        FeedbackComponent
    },
   
  };
  </script>
  
  <style scoped>
  .order-confirmation {
      text-align: center;
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: center;
  }
  
  .contact-info {
    margin-top: 30px;
    font-size: 14px;
  }
  
  h2 {
    color: #000000;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    h2 {
      font-size: 24px;
    }
  
    p {
      font-size: 16px;
    }
  }
  </style>
  