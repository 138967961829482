<template>
       <h4>Виберіть спосіб оплати:</h4>
      <div class="payment-options">
        <button 
          class="payment-button" 
          :class="{ active: paymentMethod === 'full' }" 
          @click="selectPaymentMethod('full')"
        >
        Повна оплата каткою
        </button>
        <button 
          class="payment-button" 
          :class="{ active: paymentMethod === 'cash' }" 
          @click="selectPaymentMethod('cash')"
        >
          Оплата готівкою
        </button>
      </div>
    <div class="pickup-delivery">
      <h4>Самовивіз</h4>
      <p>Самовивіз за адресою: 03110, м. Київ, вулиця Григорія Кочура, 16а</p>
      
   
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    data() {
      return {
        paymentMethod: null
      };
    },
    methods: {
      ...mapActions('checkout', ['savePaymentMethod']),
      
      // Метод для вибору методу оплати
      selectPaymentMethod(method) {
        this.paymentMethod = method;
        this.savePaymentMethod(method);
      }
    }
  };
  </script>
  
  <style scoped>
  .pickup-delivery {
    padding: 20px 0;
  }
  
  .payment-options {
    display: flex;
    gap: 20px;
  }
  
  .payment-button {
    text-transform: uppercase;
    background: #fff;
    border: 1px solid #000;
    cursor: pointer;
    transition: 0.35s;
    width: 100%;
    letter-spacing: 1px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 15px;
    font-family: 'MaisonNeue';
    margin-bottom: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .payment-button:hover {
    background-color: #fff;
    color: #000;
  }
  
  .payment-button.active {
    background-color: #000;
    color: #fff;
    border-color: #000;
  }
  </style>
  